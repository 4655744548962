.side-nav-link {
    color: #BDBDBD;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0.5rem;
    background: #253642;
    margin: 0.2rem 0.1rem 0.2rem 0.5rem;
    padding: 1rem 0 1rem 1rem;
}

.side-nav-link.active-side-nav-link {
    background: #0097C7;
}
