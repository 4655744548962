.logsContainer {
    display: grid;
    grid-template-columns: repeat(3, 33%);
}

.screenshot {
    position: relative;
}
.screenshot:hover .info {
    display: block;
}

.screenshot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px;
}
